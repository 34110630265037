/* body {
    height: 100% !important;
}
.fc {
    height: 100% !important;
}
.fc table {
    height: 100% !important;
}
.fc-daygrid {
    height: 100% !important;
} */
.fc-scroller-harness {
    height: 125% !important;
}
.fc-daygrid-day-top {
    width: 60% !important;
}
