@import url("https://use.typekit.net/cfe0xqs.css");
@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;300;400;900&display=swap");
.App {
    text-align: center;
}
.test td {
    max-width: 3% !important;
    margin-left: 100px !important;
    width: 0px;
    /* background-color: red; */
}

body {
    font-size: 16px !important;
    font-family: "Gothic A1" !important;
    scroll-behavior: smooth !important;
}

p {
    letter-spacing: 0.25px;
    line-height: 22px;
    color: #031f33 !important;
}

h3 {
    line-height: 23px;
}

h1 {
    /* line-height: 52px; */
    font-family: "brandon-grotesque" !important;
    font-weight: 500 !important;
    font-size: 36px !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.login-view {
    background-color: #17a3ab;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-view .MuiButton-containedPrimary,
.login-view .MuiButton-containedPrimary:hover {
    background-color: #f4eb76;
    font-family: "brandon-grotesque";
    line-height: 26px;
    letter-spacing: 3px;
    color: #031f33;
    font-size: 18px;
    padding-top: 17px;
    padding-bottom: 17px;
}

.MuiButton-contained {
    box-shadow: none !important;
}

/* .login-view input {

  box-sizing: border-box;
  height: 60px;
  width: 350px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;

} */

.login-view .form-container {
    margin-left: 185px;
    margin-right: 185px;
}

.extra-view {
    width: 100%;
    padding-right: 0px !important;
}

.extra-view .content {
    height: 100%;
    padding-right: 0px !important;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}
.extra-view img {
    /* margin-left:284px */
    height: 475px;
    width: 472px;
}

.extra-view .largetext {
    position: absolute;
    bottom: 154.74px;
    height: 162px;
    width: 541px;
}

#login-logo {
    margin-bottom: 50.1px;
}

.login-h1 {
    display: block;
    color: #fff;
    font-family: "brandon-grotesque";
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    text-transform: uppercase;
    /* width: 100% !important;
  border:1px solid red; */
}

.extra-view {
    background-color: #143840;
}

.full-height {
    height: 100vh;
}

.sidebar {
    /* position: fixed; */
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -100; /* Behind the navbar */
    /* padding: 37px 0 0; Height of navbar */

    background-color: #fff !important;
    color: #143840;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 80px;
    font-family: "brandon-grotesque";
}

.nav-pills {
    cursor: pointer !important;
}
.sidebar-expanded {
    position: relative;
    top: 0;
    left: 0;
    width: 280px !important;
}

.sidebar-expanded li.nav-item {
    width: 280px !important;
}

.sidebar-sticky .active {
    background-color: #143840;
    /* color:#fff !important; */
}

.sidebar-sticky .active {
    fill: #fff !important;
}

.badge-inprogress {
    border-radius: 20px;
    background-color: #ebf6f7;
    color: #17a3ab;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    width: 100%;
    font-family: "brandon-grotesque";
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
}

.badge-complete {
    border-radius: 20px;
    background-color: #ecf5ee;
    color: #269b5a;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    width: 100%;
    font-family: "brandon-grotesque";
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
}

.badge-incomplete {
    border-radius: 20px;
    background-color: #fdeeed;
    color: #e3524d;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    width: 100%;
    font-family: "brandon-grotesque";
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
}

.badge-review {
    border-radius: 20px;
    background-color: #fff4eb;
    color: #f58b23;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    width: 100%;
    font-family: "brandon-grotesque";
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
}

.badge-prep-outcome {
    border-radius: 20px;
    background-color: #767676;
    color: #ffff;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    width: 100%;
    font-family: "brandon-grotesque";
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
}

.badge-amended {
    border-radius: 20px;
    background-color: #eaf4fa;
    color: #178ccc;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    width: 100%;
    font-family: "brandon-grotesque";
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
}

.sidebar-sticky {
    display: block;
    position: fixed;
    width: 120px !important;
}

.sidebar-sticky .nav-link {
    padding-left: 30px !important;
    padding-right: 10px;
}

.sidebar-sticky .nav-link.active {
    width: 100%;
    background-color: #143840;
    color: #fff;
}

.sidebar-sticky span {
    padding-left: 10px;
}

.sidebar-sticky img {
    width: 33px;
    height: auto;
}

#sidebar-container {
    /* box-shadow: 5px 1px 10px 0 rgba(0,0,0,0.2); */
    min-height: 100vh;
    background-color: #fff;
    padding: 0;
    z-index: 100;
}

#sidebar-container li {
    /* margin-top: 10px;
  margin-bottom: 10px; */
    font-size: 15px;
    text-transform: uppercase;
}

#sidebar-container.sidebar-collapsed {
    width: 120px !important;
    text-align: center;
}

.sidebar-collapsed li.nav-item {
    width: 120px !important;
}

.sidebar-collapsed .nav-link {
    padding-left: 0px !important;
}

/* .sidebar-collapsed li.nav-item .nav-link{ */
/* margin: 0 auto; */
/* } */

/* #sidebar-container.sidebar-collapsed .nav-link{ */
/* width: 80px; */
/* } */

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
/* 
.react-datepicker-wrapper {
  width: 100% !important;
}

.dateSelectorP {
  width: 100% !important;
}

.react-datepicker {
  font-family: Open Sans, sans-serif !important;
  font-size: 0.875rem !important;
  background-color: #f4f5f7 !important;
  color: #ffff !important;
  transition: box-shadow 0.15s ease;
  display: inline-block !important;
  position: relative !important;
  border: 0 !important;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  transition: box-shadow 0.15s ease;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
}
.react-datepicker__header {
  background-color: #ffff !important;
  border-bottom: #ffff !important;
}

.react-datepicker__month-select {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow 0.15s ease;
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
}
.react-datepicker__year-select {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow 0.15s ease;
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
}
.react-datepicker__current-month {
  visibility: hidden !important;
}
.react-datepicker__navigation {
  visibility: hidden !important;
} */

.patient-card {
    background-color: #83437d !important;
    color: #fff !important;
}

.patient-card h5,
.patient-card .h2,
.patient-card .h1,
.prep-records-card h5,
.prep-records-card .h2,
.prep-records-card .h1,
.outreach-card h5,
.outreach-card .h2,
.outreach-card .h1,
.appointment-card h5,
.appointment-card .h2,
.appointment-card .h1 {
    color: #fff !important;
    font-weight: bold;
}

.prep-records-card {
    background-color: #127582 !important;
}

.outreach-card .card-title,
.patient-card .card-title,
.appointment-card .card-title,
.prep-records-card .card-title {
    /* border-bottom:0.87px solid #cad2d8 !important; */
    letter-spacing: 0.25px;
    line-height: 18px;
    padding-top: 15.4px;
    /* padding-bottom: 13.78px ; */
}

.patient-card .card-title hr,
.outreach-card .card-title hr,
.appointment-card .card-title hr,
.prep-records-card .card-title hr {
    margin-top: 13.78px;
    margin-bottom: 0px;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    opacity: 0.2;
    height: 0.87px !important;
}
.patient-card .card-body .h1,
.outreach-card .card-body .h1,
.appointment-card .card-body .h1,
.prep-records-card .card-body .h1 {
    color: #ffffff;
    font-family: "Gothic A1";
    font-size: 50px;
    font-weight: 900;
    letter-spacing: 0.8px;
    line-height: 50px;
    text-align: center;
}

.outreach-card {
    background-color: #143840 !important;
    /* opacity: 0.2; */
}

.appointment-card {
    background-color: #0f6699 !important;
}

.card-stats .border-bottom {
    border-bottom-color: #fff;
    opacity: 0.2;
}

.card-stats {
    border-radius: 0 !important;
    min-width: 200px !important;
}

.card-outline {
    box-sizing: border-box;
    border: 1px solid #143840 !important;
    background-color: #ffffff !important;
    border-radius: 0px !important;
}

.card-outline .card-title {
    color: #143840;
    font-family: "Gothic A1";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    line-height: 15px;
    text-align: center;
    margin-top: 14px !important;
    /* padding-bottom:14px !important; */
}

.card-outline .small {
    font-size: 15px !important;
}

.card-outline .card-title hr {
    margin-bottom: 0px !important;
    margin-top: 14px;
}

.card-active {
    box-sizing: border-box;
    border: 1px solid #143840 !important;
    background-color: #143840 !important;
    border-radius: 0px !important;
}

.card-active .card-title {
    color: #fff;
    font-family: "Gothic A1";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    line-height: 15px;
    text-align: center;
    margin-top: 14px !important;
}

.card-active .card-title hr {
    margin-bottom: 0px !important;
    margin-top: 14px;
    box-sizing: border-box;
    height: 1px;
    /* width: 100%; */
    border: 1px solid #ffffff;
    opacity: 0.2;
}

.card-active .card-body p {
    color: #fff !important;
    font-family: "brandon-grotesque";
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
}

.patient-metadata {
    color: #031f33;
    font-family: "Gothic A1";
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 22px;
}

.patient-tab-header {
    color: #031f33;
    font-family: "Gothic A1";
    font-size: 25px !important;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 23px;
    padding-top: 60px !important;
    padding-bottom: 31px !important;
}

.home-container {
    margin-left: 140px !important;
}

.welcome-heading {
    font-family: "brandon-grotesque";
    font-weight: bold;
    color: #17a3ab;
    font-size: 1.5rem;
    line-height: 34px;
    letter-spacing: normal;
}

.news-location-header {
    font-family: "brandon-grotesque" !important;
    font-weight: medium !important;
    color: #031f33 !important;
    font-size: 2rem !important; /*Changed from 36px to 28px*/
    line-height: normal !important; /*Changed from 52px to normal*/
    letter-spacing: normal !important;
}

.this-week {
    font-family: "Gothic A1";
    font-weight: 600;
    font-size: 1.25rem;
    color: #031f33;
    margin-bottom: 1rem;
}

.menu-btn {
    font-size: 0.6rem !important;
    color: #031f33 !important;
    padding: 0.4rem 0.75rem 0.55rem 0.75rem !important;
    border-radius: 3px !important;
    margin-right: 20px !important;
    /* padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important; */
}

.menu-searchbar-dashboard {
    width: 13rem !important;
    height: 2.45rem !important;
    font-size: 0.7rem !important;
    background-color: #fff !important;
    color: #031f33 !important;
    border: 1px solid #b3b8bf !important;
    border-radius: 3px !important;
}

.menu-searchbar {
    width: 13rem !important;
    height: 2.45rem !important;
    margin-top: 0.5rem !important;
    font-size: 0.7rem !important;
    background-color: #fff !important;
    color: #031f33 !important;
    border: 1px solid #b3b8bf !important;
    border-radius: 3px !important;
}

.form-size {
    width: 260px !important;
    height: 40px !important;
}

.form-text {
    font-family: "brandon-grotesque" !important;
    font-weight: 900 !important;
    font-size: 13px !important;
    text-transform: uppercase !important;
    color: #031f33 !important;
}

.little-text {
    font-size: 0.75rem !important;
}

.event-styling {
    width: 7.5rem !important;
    font-size: 0.5rem !important;
    margin-bottom: 0.75rem !important;
    padding: 0 1.5rem !important;
    text-align: center !important;
    border-radius: 25px !important;
    color: "#031F33" !important;
    font-weight: bold !important;
    background: rgb(23, 163, 171) !important;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
    box-shadow: rgba(0, 0, 0, 0.2) !important;
}

.appoint-card {
    font-family: "Gothic A1" !important;
    font-size: 1rem !important;
    width: 240px !important;
    background-color: #178ccc !important;
    border-radius: 20px !important;
    padding: 2rem !important;
    margin-bottom: 10px;
}

.appoint-card p {
    font-size: 1rem !important;
    color: #ffffff !important;
    line-height: 0.4 !important;
}

@media (max-width: 1380px) {
    .menu-btn {
        font-size: 0.5rem !important;
        margin-right: 0.5rem !important;
    }

    .menu-searchbar-dashboard {
        width: 12rem !important;
        height: 2.5rem !important;
        font-size: 0.5rem !important;
    }

    .news-location-header {
        font-size: 1.5rem !important;
    }
    .card-stats {
        border-radius: 0 !important;
        min-width: 170px !important;
        font: 0.75rem !important;
    }

    .patient-card,
    .prep-records-card,
    .appointment-card,
    .outreach-card {
        font: 0.75rem !important;
    }

    .patient-card .card-body .h1,
    .outreach-card .card-body .h1,
    .appointment-card .card-body .h1,
    .prep-records-card .card-body .h1 {
        font-size: 1.75rem !important;
    }

    .card-title {
        font-size: 0.75rem !important;
    }

    .event-styling {
        width: 5rem !important;
        font-size: 0.5rem !important;
        margin-bottom: 0.75rem !important;
        padding: 0 1.5rem !important;
    }

    .appoint-card {
        font-family: "Gothic A1" !important;
        font-size: 1rem !important;
        width: 240px !important;
        background-color: #178ccc !important;
        border-radius: 20px !important;
        padding: 2rem !important;
        margin-bottom: 10px;
    }

    .appoint-card p {
        font-size: 1rem !important;
        color: #ffffff !important;
        line-height: 0.4 !important;
    }
}

@media (min-width: 2160px) {
    .appoint-card {
        font-family: "Gothic A1" !important;
        font-size: 1rem !important;
        width: 360px !important;
        background-color: #178ccc !important;
        border-radius: 20px !important;
        padding: 3rem !important;
        margin-bottom: 10px;
    }

    .appoint-card p {
        font-size: 1rem !important;
        color: #ffffff !important;
        line-height: 0.4 !important;
    }

    .appoint-card-ld {
        margin-left: 7.5rem !important;
    }

    .hidden-appointment br {
        display: none;
    }
    .menu-btn {
        font-size: 0.9rem !important;
    }

    .menu-searchbar-dashboard {
        font-size: 0.9rem !important;
        width: 16rem !important;
        height: 3.125rem !important;
        margin-top: 0.125rem !important;
    }

    .menu-searchbar {
        font-size: 0.9rem !important;
        width: 16rem !important;
        height: 3.125rem !important;
        margin-top: 0.6rem !important;
    }

    .welcome-heading {
        font-size: 3rem !important;
        margin-top: 3rem !important;
        margin-left: 3rem !important;
    }

    .welcome-text {
        font-size: 2rem !important;
        margin-top: 3rem !important;
        margin-left: 3rem !important;
    }

    .news-location-header {
        font-size: 3.5rem !important;
        margin-top: 3rem !important;
        margin-left: 3rem !important;
    }

    .stats-cards {
        margin-top: 3rem !important;
        margin-left: 3rem !important;
    }

    .this-week {
        font-size: 2rem !important;
    }

    .district-select {
        margin-top: 3rem !important;
    }

    .ld-ml-5 {
        margin-left: 3.5rem !important;
    }

    .btn-primary {
        font-size: 1rem !important;
    }

    .little-text {
        font-size: 1rem !important;
    }

    .event-styling {
        width: 12rem !important;
        font-size: 0.9rem !important;
        margin-bottom: 0.75rem !important;
        padding: 0 1.5rem !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.card-outline .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-outline .card-body p {
    color: #143840;
    font-family: "brandon-grotesque";
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
}

.sea-green {
    color: #17a3ab !important;
}

.bg-edit {
    background-color: #17a3ab !important ;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}

#header-container .col {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#header-container .col h1 {
    padding-left: 60px;
    padding-right: 60px;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #17a3ab;
    border-radius: 10px;
}

.text-normal p {
    font-size: 12;
}

.text-bold p {
    font-size: 12;
    font-weight: bold !important;
}

.blue-shadow {
    -webkit-box-shadow: -4px 0px 0px 0px #17a3ab !important;
    -moz-box-shadow: -4px 0px 0px 0px #17a3ab !important;
    box-shadow: -4px 0px 0px 0px #17a3ab !important;
}

.table-statistics {
    border-spacing: 0px 9px;
    border-collapse: separate !important;
    margin-bottom: 0px !important;
    width: 714px !important;
    height: 82px !important;
}

.table-statistics tr:hover {
    -webkit-box-shadow: -4px 0px 0px 0px #17a3ab !important;
    -moz-box-shadow: -4px 0px 0px 0px #17a3ab !important;
    box-shadow: -4px 0px 0px 0px #17a3ab !important;
    transition: all ease 600ms;
}

.table {
    border-spacing: 0px 9px;
    border-collapse: separate !important;
    margin-bottom: 0px !important;
    margin-right: 60px;
}

/* .search-label {
  width: 100% !important;
  float: right;
  margin-right: -14px;
} */

/* Chadd CSS */
table {
    table-layout: fixed;
    width: 100%;
}
table th.small {
    font-size: 0.625rem !important;
}
table th.extra-small {
    font-size: 0.475rem !important;
}
/* table th.wrapping {
  white-space: pre-line;
  word-wrap: break-word;
  font-size: 0.625rem !important;
}
table th.extra-small {
  white-space: pre-line;
  word-wrap: break-word;
  font-size: 0.5rem !important;
}
table td.breaking {
  white-space: pre-line;
  word-wrap: break-word;
  font-size: 0.625rem !important;
} */
/* Dashboard Right Sidebar Start */

.db-r-sb-h {
    font-family: "brandon-grotesque" !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
    color: #fff !important;
}

.db-r-sb-t {
    font-family: "Gothic A1" !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    color: #fff !important;
}

/* Dashboard Right Sidebar End */

.btn-lg {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 15px !important;
    padding-right: 20px !important;
    /* border-radius: 14px !important; */
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:hover,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.sidebar-sticky .btn {
    padding-left: 0px !important;
}

.btn-secondary,
.btn-primary {
    box-shadow: none !important;
}

.add-border {
    padding: 1px 5px;
    border: 1px solid #031f33;
    border-radius: 4px;
}

.table thead tr {
    background-color: #b3b8bf !important;
    color: #fff !important;
}

.table tbody tr {
    background-color: #fff !important;
    border-top: #fff;
}

.table tr td {
    /* font-size: 16px; */
    font-size: 13px;
    letter-spacing: 0.25px;
    line-height: 20px;
    vertical-align: middle !important;
}

/* .table td:first-child {
  color: #17a3ab !important;
  font-weight: bold;
} */

/* .table tr > td:first-child:hover{
    border-left: #17A3AB solid 2px;
  } */

.table tbody > tr:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-left: #17a3ab solid 2px;
}

.btn {
    font-family: "brandon-grotesque";
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.8px;
    border-radius: 3px;
    box-sizing: border-box;
    text-transform: uppercase !important;
    text-align: center;
}

.btn-outline-primary {
    border-color: #17a3ab !important;
    color: #17a3ab !important;
}

.btn-outline-primary:hover {
    border-color: #17a3ab !important;
    background-color: #17a3ab !important;
    color: #fff !important;
    transition: all ease 600ms;
}

.btn-primary-dropdown {
    width: 100%;
    font-size: 0.625rem !important;
    border: 1px solid #17a3ab !important;
    background-color: #17a3ab !important;
    color: #fff !important;
}

.btn-primary-alternative {
    border: 1px solid #17a3ab !important;
    background-color: #17a3ab !important;
    color: #fff !important;
}

.btn-primary {
    font-size: 0.625rem !important;
    border: 1px solid #17a3ab !important;
    background-color: #17a3ab !important;
    color: #fff !important;
}

.fa-arrow-left {
    color: #17a3ab;
}

.btn-view {
    /* opacity: 0.3; */
    /* width: '100%'; */
    border-radius: 4px;
    background-color: #e3e6eb !important;
    color: #17a3ab !important;
    font-size: 13px !important;
}

.btn-patient {
    border-color: #f58b23 !important;
    background-color: #f58b23 !important;
    border-radius: 0px !important;
    color: #000;
    line-height: 14px !important;
}

.btn-group-activity {
    border-color: #17a3ab !important;
    background-color: #17a3ab !important;
    border-radius: 0px !important;
    color: #000;
    line-height: 14px !important;
    border-radius: 4px !important;
}

.btn-d {
    border-radius: 0px !important;
    color: #000;
    line-height: 14px !important;
    border-radius: 4px !important;
}

.view-arrow {
    height: 40px !important;
    width: 40px !important;
    border-radius: 4px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* height: 100% !important; */
}

.view-arrow img {
    height: 10px;
    width: 17px;
}

.readonly .form-control-label {
    color: #031f33;
    font-family: "Gothic A1";
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 22px;
    font-weight: normal;
}

.readonly p {
    color: #031f33;
    font-family: "Gothic A1";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 22px;
}

.fc {
    background-color: #fff !important;
    padding-top: 1.8rem !important;
    padding-bottom: 4rem !important;
    margin-bottom: 6rem !important;
    height: 100vh !important;
    margin-left: 4rem !important;
    table-layout: auto;
}

.fc th {
    font-family: "brandon-grotesque";
    font-weight: bold;
    font-size: 0.8rem;
    color: #b3b8bf;
    padding-bottom: 4rem !important;
}

.fc td,
.fc th {
    border-style: none !important;
}

.fc-theme-standard .fc-scrollgrid {
    border: none !important;
}
.fc-theme-standard td,
.fc-theme-standard th {
    border: none !important;
}
/* .css-2b097c-container{
    width: 50% !important;
  } */

.fc-day-grid-container.fc-scroller {
    height: auto !important;
    overflow-y: auto;
}

/* Appointments Calendar Day Number Styling */
/* 
.fc-daygrid-day-number {
  width: 3rem !important;
  padding-top: 3rem !important;
  padding-bottom: 1.25rem !important;
  text-align: center;
  border-radius: 25px !important;
  color: '#031F33' !important;
  font-weight: bold !important;
  background: rgb(23, 163, 171) !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
}
*/
/* .fc-day-grid-day-events {
  position: relative;
}

.fc-daygrid-day-number-darkteal {
  text-align: center;
  border-radius: 25px !important;
  color: '#031F33' !important;
  font-weight: bold !important;
  background: rgb(18, 117, 130) !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
}

.fc-daygrid-day-number .teal {
  width: 3rem !important;
  padding-top: 3rem !important;
  padding-bottom: 1.25rem !important;
  text-align: center;
  border-radius: 25px !important;
  color: '#031F33' !important;
  font-weight: bold !important;
  background: rgb(23, 163, 171) !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
}

.fc-daygrid-day-number .blue {
  width: 3rem !important;
  padding-top: 3rem !important;
  padding-bottom: 1.25rem !important;
  text-align: center;
  border-radius: 25px !important;
  color: '#031F33' !important;
  font-weight: bold !important;
  background: rgb(23, 140, 204) !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
}

.fc-daygrid-day-number .lightblue {
  width: 3rem !important;
  padding-top: 3rem !important;
  padding-bottom: 1.25rem !important;
  text-align: center;
  border-radius: 25px !important;
  color: '#031F33' !important;
  font-weight: bold !important;
  background: rgb(65, 174, 233) !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
} */

.fc-daygrid-day-top {
    position: relative;
}

.fc-event-main {
    position: absolute;
    top: 0;
    right: 0;
}

/* All Clear */

/* .fc-event-main i {
  font-family: 'brandon-grotesque';
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  color: '#FFF';
  border-radius: 25px;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
} */

/* Dark Teal */

.fc-event-main i {
    font-family: "brandon-grotesque";
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    color: "#FFF";
    background-color: rgb(18, 117, 130) !important;
    border-radius: 25px;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
    box-shadow: rgba(0, 0, 0, 0.2) !important;
}

/* Teal */

/* .fc-event-main i {
  font-family: 'brandon-grotesque';
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  color: '#FFF';
  background-color: rgb(23, 163, 171) !important;
  border-radius: 25px;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
} */

/* Blue */

/* .fc-event-main i {
  font-family: 'brandon-grotesque';
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  color: '#FFF';
  background-color: rgb(23, 140, 204) !important;
  border-radius: 25px;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
} */

/* Light Blue */

/* .fc-event-main i {
  font-family: 'brandon-grotesque';
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  color: '#FFF';
  background-color: rgb(65, 174, 233) !important;
  border-radius: 25px;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.2) !important;
} */

.dropdown-item.active,
.dropdown-item:active {
    background-color: transparent !important;
}

/* .datetime {
  width: 100%;
  height: 100%;
  font-size: 0.75rem;
  color: #8898aa;
  border: none;
} */
.rdtPicker {
    width: 400px !important;
    word-wrap: normal !important;
}
